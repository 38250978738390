import { Button, Flex, SimpleGrid, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState } from "react";
import Modal from "./EditModal";

export default function CompanyInfoCard({ user }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editedValues, setEditedValues] = useState({
    INN: "",
    BIC: "",
    website: "",
  });

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const toast = useToast();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = (field, value) => {
    setEditedValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      toast({
        title: "Все поля успешно заполнены",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      closeModal();
    }, 2000);
  };

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="left">
      <Flex justifyContent="space-between" alignItems="center" mt="10px" mb="24px">
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
          Информация об организации
        </Text>
        <Button
          onClick={openModal}
          fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative"
          colorScheme="blue"
        >
          Редактировать
        </Button>
      </Flex>

      <SimpleGrid columns="3"  gridTemplateRows="repeat(8, 1fr)" gridAutoFlow="column dense" spacingY="10px" spacingX="40px">
        {[
          ["Полное наименование:", "fullCompanyName"],
          ["Краткое наименование:", "shortCompanyName"],
          ["ИНН:", "INN"],
          ["КПП:", "KPP"],
          ["ОГРН:", "OGRN"],
          ["Адрес местонахождения:", "address"],
          ["Вебсайт:", "website"],
          ["ФИО единоличного исполнительного органа:", "executiveName"],
          ["Должность руководителя:", "executivePosition"],
          ["Основной и дополнительные ОКВЭД:", "OKVED"],
          ["ФИО учредителей:", "founders"],
          ["ОКПО:", "OKPO"],
          ["ПФР:", "PFR"],
          ["ФСС:", "FSS"],
          ["ОКАТО:", "OKATO"],
          ["ОКТМО:", "OKTMO"],
          ["ОКОПФ:", "OKOPF"],
          ["ОКОГУ:", "OKOGU"],
          ["ОКФС:", "OKFS"],
          ["Название банка:", "bankName"],
          ["БИК:", "BIC"],
          ["Корр. счёт:", "correspondentAccount"],
          ["Расчётный счёт:", "accountNumber"],
          ["Дата открытия счёта:", "accountOpeningDate"]
        ].map(([label, field]) => (
          <Flex key={field} direction="column">
            <Text color={textColorSecondary} fontSize="md">{label}</Text>
            <Text color={textColorPrimary} fontSize="md">
              {Array.isArray(user[field])
                ? user[field].join(", ")
                : user[field] || "---"}
            </Text>
          </Flex>
        ))}
      </SimpleGrid>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          editedValues={editedValues}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      )}
    </Card>
  );
}
