import React, { useContext, useState, useMemo, useEffect } from "react";
import { DataContext } from "contexts/DataContext";
import { UserContext } from "contexts/UserContext";
import { Box, Heading, Text, Tabs, TabList, TabPanels, Flex, Icon, Tab, TabPanel, Step, StepIndicator, StepDescription, StepNumber, StepSeparator, Stepper, StepStatus, StepTitle } from '@chakra-ui/react';
import Card from "components/card/Card.js";
import { useParams } from 'react-router-dom';
import IconBox from "components/icons/IconBox";
import { MdOutlinePendingActions, MdCheckCircle } from "react-icons/md";

import OrderParams from 'views/admin/order/panels/OrderParams';
import Agreement from 'views/admin/order/panels/Agreement';
import TransferInstruction from 'views/admin/order/panels/TransferInstruction';
import WorkReport from 'views/admin/order/panels/WorkReport';
import Chat from 'views/admin/order/panels/Chat';

const statuses = [
  { title: "Черновик", description: "Подготовка заявки", status: "черновик" },
  { title: "В работе", description: "Заявка в процессе", status: "в работе" },
  { title: "Верификация заявки", description: "Проверка данных", status: "верификация" },
  { title: "Агентский договор", description: "Согласование и подписание", status: "подписание договора" },
  { title: "Поручение на перевод", description: "Согласование и подписание", status: "согласование поручения" },
  { title: "Исполнение платежа", description: "Перечисление денег", status: "оплата" },
  { title: "Акт выполненных работ", description: "Подписание акта", status: "подписание акта" },
  { title: "Заявка исполнена", description: "Заявка завершена", status: "исполнена" },
];

export default function OrderDetails() {
  const { orders } = useContext(DataContext);
  const { getFullUserData } = useContext(UserContext);
  const { id } = useParams();
  const numericId = Number(id);
  const order = orders.find((o) => o.id === numericId);
  const thisUser = getFullUserData();

  const activeStep = useMemo(() => statuses.findIndex(status => status.status === order.status), [order.status]);

  const getTabVisibility = () => {
    switch (order.status) {
      case "черновик":
      case "в работе":
      case "верификация":
        return [true, false, false, false, true];
      case "подписание договора":
        return [true, true, false, false, true];
      case "согласование поручения":
      case "оплата":
        return [true, true, true, false, true];
      case "подписание акта":
      case "исполнена":
        return [true, true, true, true, true];
      default:
        return [false, false, false, false, false];
    }
  };

  const [activeTabs, setActiveTabs] = useState(getTabVisibility());
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const visibleTabs = getTabVisibility();
    setActiveTabs(visibleTabs);
    // Находим последнюю доступную вкладку, кроме последней вкладки "Чат"
    const lastEnabledIndex = visibleTabs.slice(0, -1).lastIndexOf(true);
    setActiveIndex(lastEnabledIndex >= 0 ? lastEnabledIndex : 0);
  }, [order.status]);

  const handleNextTab = (index) => {
    setActiveTabs((prev) => prev.map((active, i) => i === index + 1 ? true : active));
    setActiveIndex(index + 1);
  };

  if (!order) {
    return <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>Заявка не найдена</Box>;
  }

  const title = `Заявка на ${order.type === 'export' ? 'отправку платежа' : 'получение платежа'} № ${order.id} от\u00A0${order.datum}`;

  return (
    <>
      <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px">
        <IconBox
          w='56px'
          h='56px'
          bg="white"
          float="left" 
          icon={
            <Icon w='32px' h='32px' as={MdOutlinePendingActions} color="navy.500" />
          }
        />
        <Flex pl="20px" direction="column">
          <Heading fontSize="27px" lineHeight="125%">
            {title}
          </Heading>
          <Text fontSize="12px" fontWeight="bold" color="gray.500" textTransform="uppercase">
            Статус: <Text display="inline-block" color="green.500">{order.status}</Text>
          </Text>
        </Flex>
      </Flex>

      <Card w="100%" p={{ base: "20px", md: "40px" }}>
        <Flex direction={{ base: "column", lg: "row" }} justify="space-between">
          <Box flex="1" pr={{ base: "0", lg: "20px" }}>
            <Tabs mt={5} index={activeIndex} onChange={(index) => setActiveIndex(index)}>
              <TabList>
                <Tab fontSize="15px">Параметры заявки</Tab>
                <Tab fontSize="15px" isDisabled={!activeTabs[1]}>Агентский договор</Tab>
                <Tab fontSize="15px" isDisabled={!activeTabs[2]}>Поручение на перевод</Tab>
                <Tab fontSize="15px" isDisabled={!activeTabs[3]}>Акт выполненных работ</Tab>
                <Tab fontSize="15px">Чат</Tab>
              </TabList>
              <TabPanels mt={8}>
                <TabPanel>
                  <OrderParams order={order} user={thisUser} onNext={() => handleNextTab(0)} />
                </TabPanel>
                <TabPanel>
                  <Agreement order={order} user={thisUser} onNext={() => handleNextTab(1)} />
                </TabPanel>
                <TabPanel>
                  <TransferInstruction order={order} user={thisUser} onNext={() => handleNextTab(2)} />
                </TabPanel>
                <TabPanel>
                  <WorkReport order={order} user={thisUser} />
                </TabPanel>
                <TabPanel>
                  <Chat />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
          <Box width="300px" p={4} bg="gray.100" borderRadius="md">
            <Text fontSize="md" mt="10px" mb="25px" fontWeight="bold">
              Этапы работы с заявкой
            </Text>
            <Stepper
              index={activeStep}
              colorScheme="blue"
              orientation="vertical"
              align="flex-start"
              ml={{ base: "0", lg: "20px" }}
              mt={{ base: "20px", lg: "0" }}
            >
              {statuses.map((status, index) => (
                <Step key={index} orientation="horizontal" alignItems="center">
                  <StepIndicator>
                    <StepStatus
                      complete={<MdCheckCircle color="green.500" />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <Box flexShrink="0">
                    <StepTitle>{status.title}</StepTitle>
                    <StepDescription>{status.description}</StepDescription>
                  </Box>
                  <StepSeparator height="1rem!important" maxHeight="1rem!important" top="2.8rem!important" />
                </Step>
              ))}
            </Stepper>
          </Box>
        </Flex>
      </Card>
    </>
  );
}
