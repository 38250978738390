
// Chakra imports
import { Box, Flex, Text, Icon } from "@chakra-ui/react";
import ComplexTable from "views/admin/orders/components/ComplexTable";
import React, { useContext } from "react";
import { DataContext } from "contexts/DataContext";
import IconBox from "components/icons/IconBox";
import {
  MdDescription
} from "react-icons/md";

export default function Orders() {
  const { orders } = useContext(DataContext);

  
  return (
    <>
    <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px" >
    <IconBox
          w='56px'
          h='56px'
          bg="white"
          float="left" 
          icon={
            <Icon w='32px' h='32px' as={MdDescription} color="navy.500" />
          }
        />
<Text pl="20px" fontSize="30px" fontWeight="bold" lineHeight="140%">Мои заявки</Text>
    </Flex>
    <Box>
        <ComplexTable
          tableData={orders}
        />
    </Box>
    </>
  );
}
