import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  Text,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useContext } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";

import { DataContext } from "contexts/DataContext";
import SimpleTable from "views/admin/orders/components/SimpleTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import CurrencyRates from "views/admin/default/components/CurrencyRates";


export default function UserReports() {
  const { orders } = useContext(DataContext);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
  <>
    <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px" >
    <IconBox
          w='56px'
          h='56px'
          bg="white"
          float="left"
          icon={
            <Icon w='32px' h='32px' as={MdBarChart} color="navy.500" />
          }
        />
<Text pl="20px" fontSize="30px" fontWeight="bold" lineHeight="140%">Аналитика</Text>
    </Flex>

    <Box>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='В работе'
          value='$350.4'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='В этом месяце'
          value='$642.39'
        />
        <MiniStatistics growth='+23%' name='Общая сумма' value='$574.34' />
        <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='rub'>
                <option value='usd'>USD</option>
                <option value='cny'>CNY</option>
                <option value='rub'>RUB</option>
              </Select>
            </Flex>
          }
          name='Общая сумма'
          value='$1,000'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='Заявок в работе'
          value='4'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Новых проектов'
          value='19'
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
      <CurrencyRates />
      <SimpleTable
            tableData={orders}
            title="Последние заявки"
            link="/admin/orders"
            limit="6" />
        <SimpleTable
            tableData={orders}
            title="Черновики"
            status="черновик"
            link="/admin/orders/drafts" />
            <SimpleTable
            tableData={orders}
            title="Выполненные заявки"
            status="исполнена"
            link="/admin/orders/completed" />
    
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap='20px' mb='20px'>
      <DailyTraffic />
          <PieCard />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
      </SimpleGrid>
    </Box>
    </>
  );
}
