import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Chat = () => (
  <Box>
    <Text>Чат по заявке</Text>
  </Box>
);

export default Chat;
