import React, { useState, useRef } from 'react';
import {
  Button,
  Box,
  Stepper,
  Select,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepNumber,
  StepDescription,
  StepSeparator,
  useSteps,
  Text,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
  Grid,
  Input,
  Icon
} from '@chakra-ui/react';
import { MdCheckCircle, MdCallMade, MdCallReceived, MdUpload } from "react-icons/md"; // Подключаем иконки из react-icons

const stepsData = [
  { title: 'Экспорт/Импорт', description: 'Выберите операцию' },
  { title: 'Детали заявки', description: 'Заполните форму' }
];


const RequestFormSteps = ({ formData, handleChange, handleFileUpload, handleSubmit, onSuccessOpen, isFormModified }) => {

  const [operationType, setOperationType] = useState(''); // Состояние для хранения выбранной операции (импорт/экспорт)

  const { activeStep, setActiveStep } = useSteps({
    initialStep: 0,
  });


  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const prevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isFilesUploaded = (
    (formData.contract instanceof File && formData.invoice instanceof File) || // Для импортёра
    (formData.companyCard instanceof File && formData.specialization instanceof File && formData.russiaCompanyCard instanceof File) // Для экспортёра
  );



  // Функция обработки выбора операции
  const handleOperationSelect = (type) => {
    setOperationType(type); // Устанавливаем тип операции
    nextStep(); // Переход к следующему шагу
  };

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";

  const contractInputRef = useRef(null); // Реф для договора
  const invoiceInputRef = useRef(null); // Реф для счета

  const handleContractClick = () => {
    contractInputRef.current.click(); // Симулируем клик по инпуту договора
  };

  const handleInvoiceClick = () => {
    invoiceInputRef.current.click(); // Симулируем клик по инпуту счета
  };


  const [contractFileName, setContractFileName] = useState(""); // Состояние для названия файла договора
  const [invoiceFileName, setInvoiceFileName] = useState(""); // Состояние для названия файла счета


  const companyCardRef = useRef(null); // Реф для Карточки компании
  const specializationRef = useRef(null); // Реф для Сведения о специализации компании
  const russiaCompanyCardRef = useRef(null); // Реф для Карточки компании в РФ

  const [companyCardFileName, setCompanyCardFileName] = useState(""); // Состояние для названия файла Карточки компании
  const [specializationFileName, setSpecializationFileName] = useState(""); // Состояние для Сведения о специализации
  const [russiaCompanyCardFileName, setRussiaCompanyCardFileName] = useState(""); // Состояние для Карточки компании в РФ


  const handleCompanyCardClick = () => {
    companyCardRef.current.click(); // Открываем окно выбора файла для Карточки компании
  };

  const handleSpecializationClick = () => {
    specializationRef.current.click(); // Открываем окно выбора файла для специализации компании
  };

  const handleRussiaCompanyCardClick = () => {
    russiaCompanyCardRef.current.click(); // Открываем окно выбора файла для Карточки компании в РФ
  };

  const handleCompanyCardUpload = (event) => {
    const file = event.target.files[0];
    setCompanyCardFileName(file.name); // Устанавливаем название загруженного файла
    handleFileUpload(event); // Выполняем обработку файла
  };

  const handleSpecializationUpload = (event) => {
    const file = event.target.files[0];
    setSpecializationFileName(file.name); // Устанавливаем название загруженного файла
    handleFileUpload(event); // Выполняем обработку файла
  };

  const handleRussiaCompanyCardUpload = (event) => {
    const file = event.target.files[0];
    setRussiaCompanyCardFileName(file.name); // Устанавливаем название загруженного файла
    handleFileUpload(event); // Выполняем обработку файла
  };


    // Функция обработки загрузки файлов для контракта
    const handleContractUpload = (event) => {
      const file = event.target.files[0];
      setContractFileName(file.name); // Устанавливаем название загруженного файла
      handleFileUpload(event); // Выполняем обработку файла
    };
  
    // Функция обработки загрузки файлов для счета
    const handleInvoiceUpload = (event) => {
      const file = event.target.files[0];
      setInvoiceFileName(file.name); // Устанавливаем название загруженного файла
      handleFileUpload(event); // Выполняем обработку файла
    };

  return (
    <>
      {/* Stepper */}
      <Box p={4}>
        <Stepper index={activeStep} colorScheme="blue" maxW="60%" ml="auto" mr="auto" mb={5}>
          {stepsData.map((stepData, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<MdCheckCircle />} 
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{stepData.title}</StepTitle>
                <StepDescription>{stepData.description}</StepDescription>
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Содержание шагов */}
      <Box>
        {/* Шаг 1: Выбор типа клиента */}
        {activeStep === 0 && (
           
          <Box>
            <Text fontSize="xl" mb={5} textAlign="center" fontWeight="bold">
  Выберите тип заявки
</Text>
            <Flex justify="center" align="center" gap={4} mb={4}>
              <Button
                size="lg"
                variant='outline'
                width="250px"
                height="250px"
                bg="gray.50"
                _hover={{ bg: "white" }} 
                flexDirection="column"
                onClick={() => handleOperationSelect('import')} // Выбор "Импорт"
              >
                <Icon as={MdCallMade} w={12} h={12} mb={2} color="blue.500" />
                <Text fontSize="md" mb={2}>Импорт</Text>
                <Text fontSize="sm" color="gray.500">Мне надо оплатить</Text>
              </Button>

              <Button
                size="lg"
                variant='outline'
                width="250px"
                height="250px"
                bg="gray.50"
                _hover={{ bg: "white" }} 
                flexDirection="column"
                onClick={() => handleOperationSelect('export')} // Выбор "Экспорт"
              >
                <Icon as={MdCallReceived} w={12} h={12} mb={2} color="green.500" />
                <Text fontSize="md" mb={2}>Экспорт</Text>
                <Text fontSize="sm" color="gray.500">Мне надо вернуть</Text>
                <Text fontSize="sm" color="gray.500">валютную выручку</Text>
              </Button>
            </Flex>
          </Box>
        )}

        {/* Шаг 2: Импорт */}
        {activeStep === 1 && (
          <>
          <Text fontSize="xl" mb={7} textAlign="center" fontWeight="bold">
          Загрузите документы и заполните детали заявки
        </Text>
          <Flex justifyContent="space-between" alignItems="flex-start">
{/* Левая часть: Поля */}


<Flex direction="column" flex="1" pe="44px" w="100%">
<Box mb={8} cursor="pointer" onClick={handleContractClick}>
        <Flex h="100%" direction="column" align="center">
          <Box textAlign="center" p={4} bg="white">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="md" >
              Загрузите Договор
            </Text>
            <Text color={textColorPrimary} fontWeight="bold" fontSize="sm" mb={2} >
              Contract
            </Text>
            <Icon as={MdUpload} w="50px" h="50px" color="gray.500"/>

            <Flex alignItems="center" justify="center" mb={3}>
              {contractFileName ? (
                <>
                  <Icon as={MdCheckCircle} w="6" h="6" color="green.500" mr={2} />
                  <Text color={textColorPrimary} fontSize="md">
                    {contractFileName}
                  </Text>
                </>
              ) : (
                <Text color={textColorSecondary} fontSize="sm">
                  Загрузите, пожалуйста, электронные копии документов
                </Text>
              )}
            </Flex>
            <Input
              ref={contractInputRef}
              type="file"
              name="contract"
              onChange={handleContractUpload}
              accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,.xls"
              style={{ display: "none" }} // Скрываем инпут
            />
            <Text color={brandColor} fontWeight="bold">
              {contractFileName ? "Нажмите для изменения файла" : "Нажмите для выбора файла"}
            </Text>
          </Box>
        </Flex>
      </Box>

      {/* Загрузка счёта */}
      <Box mb={8} cursor="pointer" onClick={handleInvoiceClick}>
        <Flex h="100%" direction="column" align="center">
          <Box textAlign="center" bg="white" p={4}>
          <Text color={textColorPrimary} fontWeight="bold" fontSize="md" >
              Загрузите Счёт
            </Text>
            <Text color={textColorPrimary} fontWeight="bold" fontSize="sm" mb={2} >
              Invoice
            </Text>
            <Icon as={MdUpload} w="50px" h="50px" color="gray.500" />

            <Flex alignItems="center" justify="center" mb={3}>
              {invoiceFileName ? (
                <>
                  <Icon as={MdCheckCircle} w="6" h="6" color="green.500" mr={2} />
                  <Text color={textColorPrimary} fontSize="md">
                    {invoiceFileName}
                  </Text>
                </>
              ) : (
                <Text color={textColorSecondary} fontSize="sm">
                  Загрузите, пожалуйста, электронные копии документов
                </Text>
              )}
            </Flex>
            <Input
              ref={invoiceInputRef}
              type="file"
              name="invoice"
              onChange={handleInvoiceUpload}
              accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,.xls"
              style={{ display: "none" }} // Скрываем инпут
            />
            <Text color={brandColor} fontWeight="bold">
              {invoiceFileName ? "Нажмите для изменения файла" : "Нажмите для выбора файла"}
            </Text>
          </Box>
        </Flex>
      </Box>
  {operationType === 'export' && (
<>
<Box mb={8} cursor="pointer" onClick={handleCompanyCardClick}>
        <Flex h="100%" direction="column" align="center">
          <Box textAlign="center" p={4} bg="white">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="md">
              Карточка компании
            </Text>
            <Text color={textColorPrimary} fontWeight="bold" fontSize="sm" mb={2}>
              Company card
            </Text>
            <Icon as={MdUpload} w="50px" h="50px" color="gray.500" />
            <Flex alignItems="center" justify="center" mb={3}>
              {companyCardFileName ? (
                <>
                  <Icon as={MdCheckCircle} w="6" h="6" color="green.500" mr={2} />
                  <Text color={textColorPrimary} fontSize="md">
                    {companyCardFileName}
                  </Text>
                </>
              ) : (
                <Text color={textColorSecondary} fontSize="sm">
                  Загрузите, пожалуйста, электронные копии документов
                </Text>
              )}
            </Flex>
            <Input
              ref={companyCardRef}
              type="file"
              name="companyCard"
              onChange={handleCompanyCardUpload}
              accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,.xls"
              style={{ display: "none" }} // Скрываем инпут
            />
            <Text color={brandColor} fontWeight="bold">
              {companyCardFileName ? "Нажмите для изменения файла" : "Нажмите для выбора файла"}
            </Text>
          </Box>
        </Flex>
      </Box>

      {/* Сведения о специализации компании */}
      <Box mb={8} cursor="pointer" onClick={handleSpecializationClick}>
        <Flex h="100%" direction="column" align="center">
          <Box textAlign="center" p={4} bg="white">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="md">
              Сведения о специализации компании
            </Text>
            <Text color={textColorPrimary} fontWeight="bold" fontSize="sm" mb={2}>
              Specialization or Trade license
            </Text>
            <Icon as={MdUpload} w="50px" h="50px" color="gray.500" />
  
            <Flex alignItems="center" justify="center" mb={3}>
              {specializationFileName ? (
                <>
                  <Icon as={MdCheckCircle} w="6" h="6" color="green.500" mr={2} />
                  <Text color={textColorPrimary} fontSize="md">
                    {specializationFileName}
                  </Text>
                </>
              ) : (
                <Text color={textColorSecondary} fontSize="sm">
                  Загрузите, пожалуйста, электронные копии документов
                </Text>
              )}
            </Flex>
            <Input
              ref={specializationRef}
              type="file"
              name="specialization"
              onChange={handleSpecializationUpload}
              accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,.xls"
              style={{ display: "none" }} // Скрываем инпут
            />
            <Text color={brandColor} fontWeight="bold">
              {specializationFileName ? "Нажмите для изменения файла" : "Нажмите для выбора файла"}
            </Text>
          </Box>
        </Flex>
      </Box>

      {/* Карточка компании, на которую необходимо заплатить в РФ */}
      <Box mb={8} cursor="pointer" onClick={handleRussiaCompanyCardClick}>
        <Flex h="100%" direction="column" align="center">
          <Box textAlign="center" p={4} bg="white">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="md">
              Карточка компании, на которую необходимо заплатить в РФ
            </Text>
            <Text color={textColorPrimary} fontWeight="bold" fontSize="sm" mb={2}>
              Russia company card
            </Text>
            <Icon as={MdUpload} w="50px" h="50px" color="gray.500" />
            <Flex alignItems="center" justify="center" mb={3}>
              {russiaCompanyCardFileName ? (
                <>
                  <Icon as={MdCheckCircle} w="6" h="6" color="green.500" mr={2} />
                  <Text color={textColorPrimary} fontSize="md">
                    {russiaCompanyCardFileName}
                  </Text>
                </>
              ) : (
                <Text color={textColorSecondary} fontSize="sm">
                  Загрузите, пожалуйста, электронные копии документов
                </Text>
              )}
            </Flex>
            <Input
              ref={russiaCompanyCardRef}
              type="file"
              name="russiaCompanyCard"
              onChange={handleRussiaCompanyCardUpload}
              accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,.xls"
              style={{ display: "none" }} // Скрываем инпут
            />
            <Text color={brandColor} fontWeight="bold">
              {russiaCompanyCardFileName ? "Нажмите для изменения файла" : "Нажмите для выбора файла"}
            </Text>
          </Box>
        </Flex>
      </Box>
   </>
  )}

      {/* Кнопка отправки */}
      <Button
        isDisabled={!isFilesUploaded}
        colorScheme="blue"
        width="100%"
        onClick={handleSubmit}
      >
        <Flex direction="column" align="center">
          <Text fontSize="12">Не хочу заполнять другие поля</Text>
          <Text fontSize="14">Отправить в работу</Text>
        </Flex>
      </Button>
    </Flex>

{/* Правая часть: Поля */}
<Box flex="3">
<Grid templateColumns="repeat(2, 1fr)" gap={6}>
<Box p={4} bg="white" borderRadius="md">
<Text fontSize="md" fontWeight="bold" mb={4}>
{operationType === 'import' ? 'Банк Получателя / Beneficiary Bank' : 'Банк Отправителя / Sender Bank'}
</Text>
<Box>
<FormControl>
<FormLabel>
<Text fontSize="sm">
{operationType === 'import' ? 'Наименование Банка Получателя' : 'Наименование Банка Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Name' : 'Sender Bank Name'}
</Text>
</FormLabel>
<Input
type="text"
name="partnerBankName"
value={formData.partnerBankName}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{operationType === 'import' ? 'Страна Банка Получателя' : 'Страна Банка Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Country' : 'Sender Bank Country'}
</Text>
</FormLabel>
<Input
type="text"
name="partnerBankCountry"
value={formData.partnerBankCountry}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{operationType === 'import' ? 'Адрес Банка Получателя' : 'Адрес Банка Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Address' : 'Sender Bank Address'}
</Text>
</FormLabel>
<Input
type="text"
name="partnerBankAddress"
value={formData.partnerBankAddress}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{operationType === 'import' ? 'Код Банка Получателя (SWIFT)' : 'Код Банка Отправителя (SWIFT)'}
 </Text>
<Text fontSize="xs" color="gray.500">
  SWIFT ID# of Foreign Bank
</Text>
</FormLabel>
<Input
type="text"
name="partnerBankSwift"
value={formData.partnerBankSwift}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>
</Box>

</Box>

{/* Блок: Получатель */}
<Box p={4} bg="white" borderRadius="md">
<Text fontSize="md" fontWeight="bold" mb={4}>
{operationType === 'import' ? 'Получатель / Beneficiary' : 'Отправитель / Sender'}
</Text>
<Box>
<FormControl>
<FormLabel>
<Text fontSize="sm">
{operationType === 'import' ? 'Наименование Получателя' : 'Наименование Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Name on Account' : 'Sender Name on Account'}
</Text>
</FormLabel>
<Input
type="text"
name="partnerName"
value={formData.partnerName}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{operationType === 'import' ? 'Адрес Получателя' : 'Адрес Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Address' : 'Sender Address'}
</Text>
</FormLabel>
<Input
type="text"
name="partnerAddress"
value={formData.partnerAddress}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{operationType === 'import' ? 'Счет Получателя' : 'Счет Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Account# or IBAN#' : 'Sender Bank Account# or IBAN#'}
</Text>
</FormLabel>
<Input
type="text"
name="partnerAccount"
value={formData.partnerAccount}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{operationType === 'import' ? 'Веб-сайт получателя' : 'Веб-сайт отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
Website
</Text>
</FormLabel>
<Input
type="url"
name="partnerWebsite"
value={formData.partnerWebsite}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>
</Box>
</Box>

{/* Блок: Контракт и счёт */}
<Box p={4} bg="white" borderRadius="md">
<Text fontSize="md" fontWeight="bold" mb={4}>Контракт и счёт / Contract and Invoice</Text>
<Box>
<FormControl>
<FormLabel>
<Text fontSize="sm">Описание товара/услуги</Text>
<Text fontSize="xs" color="gray.500">Product/service description</Text>
</FormLabel>
<Input
type="text"
name="productDescription"
value={formData.productDescription}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">Номер инвойса</Text>
<Text fontSize="xs" color="gray.500">No of invoice</Text>
</FormLabel>
<Input
type="text"
name="invoiceNumber"
value={formData.invoiceNumber}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">Дата инвойса</Text>
<Text fontSize="xs" color="gray.500">Date of invoice</Text>
</FormLabel>
<Input
type="date"
name="invoiceDate"
defaultValue="" 
value={formData.invoiceDate}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>
</Box>

<Box>
<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{operationType === 'import' ? 'Номер контракта c поставщиком' : 'Номер контракта c покупателем'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'No of contract with supplier' : 'No of contract with buyer'}
</Text>
</FormLabel>
<Input
type="text"
name="contractNumber"
value={formData.contractNumber}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">
{operationType === 'import' ? 'Дата контракта c поставщиком' : 'Дата контракта c покупателем'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Date of contract with supplier' : 'Date of contract with buyer'}
</Text>
</FormLabel>
<Input
type="date"
name="contractDate"
defaultValue="" 
value={formData.contractDate}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">Код ТНВД (необязательно)</Text>
<Text fontSize="xs" color="gray.500">TNVD Code</Text>
</FormLabel>
<Input
type="text"
name="tnvdCode"
value={formData.tnvdCode}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>
</Box>
</Box>

{/* Блок: Платеж */}
<Box p={4} bg="white" borderRadius="md">
<Text fontSize="md" fontWeight="bold" mb={4}>Платеж / Payment</Text>
<Box>
<FormControl>
<FormLabel>
<Text fontSize="sm">Назначение платежа</Text>
<Text fontSize="xs" color="gray.500">Details of payment</Text>
</FormLabel>
<Input
type="text"
name="paymentPurpose"
value={formData.paymentPurpose}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>

<FormControl mt={4}>
<FormLabel>
<Text fontSize="sm">Сумма платежа</Text>
<Text fontSize="xs" color="gray.500">Amount of payment</Text>
</FormLabel>
<Input
type="number"
name="paymentAmount"
value={formData.paymentAmount}
onChange={handleChange}
isDisabled={!isFilesUploaded}
/>
</FormControl>
</Box>

<Box>
<FormControl>
<FormLabel>
<Text fontSize="sm">Валюта</Text>
<Text fontSize="xs" color="gray.500">Currency</Text>
</FormLabel>
<Select
name="currency"
value={formData.currency}
onChange={handleChange}
isDisabled={!isFilesUploaded}
>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="RUB">RUB</option>
                <option value="CNY">CNY</option>
                <option value="AED">AED</option>
                <option value="CNH">CNH</option>
</Select>
</FormControl>
</Box>
</Box>
</Grid>

<Box>

</Box>

</Box>



</Flex>
</>
        )}
</Box>

      <Box display="flex" justifyContent="space-between" mt={6}>
  {activeStep > 0 && (
    <>
    <Button variant="outline" onClick={prevStep}>
      Назад
    </Button>

  <Button         colorScheme="blue"
        size="lg"
        fontSize="15px" isDisabled={!isFilesUploaded} onClick={() => { handleSubmit(); onSuccessOpen(); }}>
    Отправить заявку
  </Button>
  </>
    )}
</Box>

    </>
  );
};

export default RequestFormSteps;

