import React from 'react';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Profile from 'views/admin/profile';
import Orders from 'views/admin/orders';
import Drafts from 'views/admin/orders/drafts';
import Canceled from 'views/admin/orders/canceled';
import Completed from 'views/admin/orders/completed';
import Current from 'views/admin/orders/current';
import New from 'views/admin/orders/new';
import Order from 'views/admin/order';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import RegisterCentered from 'views/auth/register';
import PasswordCentered from 'views/auth/passwordRecovery';


const routes = [
  {
    name: 'Рабочий кабинет',
    layout: '/admin',
    path: '/default',
    component: <MainDashboard />,
  },
  {
    name: 'Заявки в работе',
    layout: '/admin',
    path: '/orders',
    component: <Orders />,
  },
  {
    name: 'Черновики',
    layout: '/admin',
    path: '/orders/drafts',
    component: <Drafts />,
  },
  {
    name: 'Новые',
    layout: '/admin',
    path: '/orders/new',
    component: <New />,
  },
  {
    name: 'Отмененные',
    layout: '/admin',
    path: '/orders/canceled',
    component: <Canceled />,
  },
  {
    name: 'В работе',
    layout: '/admin',
    path: '/orders/current',
    component: <Current />,
  },
  {
    name: 'Завершенные',
    layout: '/admin',
    path: '/orders/completed',
    component: <Completed />,
  },
  {
    name: 'Личный кабинет',
    layout: '/admin',
    path: '/profile',
    component: <Profile />,
  },
  {
    name: 'Детали заявки',
    layout: '/admin',
    path: '/orders/:id',
    component: <Order />,
  },
  {
    name: 'Войти',
    layout: '/auth',
    path: '/sign-in',
    component: <SignInCentered />,
  },
  {
    name: 'Зарегистрироваться',
    layout: '/auth',
    path: '/register',
    component: <RegisterCentered />,
  },
  {
    name: 'Восстановление пароля',
    layout: '/auth',
    path: '/password-recovery',
    component: <PasswordCentered />,
  },

];

export default routes;
