import React, { createContext, useState, useEffect, useContext } from "react";
import Orders from "db/orders.json"; // Текущие данные
import { UserContext } from "contexts/UserContext"; // Импорт UserContext

// Создаем DataContext
export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [orders, setOrders] = useState(Orders); // Изначально загружаем данные из JSON
  const { user } = useContext(UserContext); // Получаем данные о пользователе из UserContext

  // Пример функции для загрузки данных с бэкенда (замените URL на актуальный)
  // const fetchOrders = async () => {
  //   try {
  //     const response = await fetch("https://api.example.com/orders");
   //    const data = await response.json();
   //    setOrders(data);
    // } catch (error) {
   //    console.error("Error fetching orders:", error);
   //  }
  // };

  // Фильтрация заказов в зависимости от типа пользователя
  const filteredOrders = orders.filter((order) => {
 
    if (user?.clientType === "client") {
      return order.clientId === user.clientId;
    } else if (user?.clientType === "agent") {
      return order.agentId === user.agentId;
    }
    return true; // Если нет типа пользователя, возвращаем все заказы
  });

  // Используем useEffect для вызова fetchOrders при необходимости
  useEffect(() => {
    // fetchOrders(); // Раскомментируйте для включения загрузки с бэкенда
  }, []);

  return (
    <DataContext.Provider value={{ orders: filteredOrders, setOrders }}>
      {children}
    </DataContext.Provider>
  );
};
