import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { MdCreate } from "react-icons/md";
import RequestFormSteps from './RequestFormSteps'; // Подключаем компонент шагов формы

const RequestModal = () => {
  const { isOpen, onOpen: openModal, onClose } = useDisclosure();
  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure(); // Модальное окно подтверждения
  const { isOpen: isDraftOpen, onOpen: onDraftOpen, onClose: onDraftClose } = useDisclosure(); // Модальное окно для черновиков
  const [isFormModified, setIsFormModified] = useState(false); // Состояние для отслеживания изменений в форме

  const [formData, setFormData] = useState({
    clientType: '',
    fillOption: '',
    contract: '',
    invoice: '',
    swift: '',
    bankCountry: '',
    bankName: '',
    tnved: '',
    paymentPurpose: '',
    currency: '',
    amount: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setIsFormModified(true); // Отслеживаем, были ли изменения в форме
  };

  const handleFileUpload = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    setIsFormModified(true); // Отслеживаем изменения при загрузке файлов
  };

  // Обработка закрытия основного модального окна
  const handleClose = () => {
    if (isFormModified) {
      onDraftOpen(); // Открываем модальное окно с вопросом о сохранении черновика
    } else {
      onClose(); // Если изменений нет, просто закрываем
    }
  };

  const handleSaveDraft = () => {
    console.log('Заявка сохранена как черновик:', formData);
    onDraftClose();
    onClose(); // Закрываем оба модальных окна
    resetForm(); // Сбрасываем форму
  };

  const handleSubmit = () => {
    console.log('Form Data Submitted:', formData);
    onClose(); // Закрыть основное модальное окно с формой
    onSuccessOpen(); // Открыть модальное окно подтверждения
    resetForm(); // Сбросить форму
  };

  // Функция для сброса данных формы
  const resetForm = () => {
    setFormData({
      clientType: '',
      fillOption: '',
      contract: '',
      invoice: '',
      swift: '',
      bankCountry: '',
      bankName: '',
      tnved: '',
      paymentPurpose: '',
      currency: '',
      amount: ''
    });
    setIsFormModified(false); // Сбрасываем состояние изменений
  };

  // Функция открытия модального окна
  const handleOpen = () => {
    resetForm(); // Сброс формы перед открытием
    openModal(); // Открытие модального окна
  };

  return (
    <>
      <Button mt={25} onClick={handleOpen} rightIcon={<MdCreate />} colorScheme='blue' variant='outline'>
        Создать заявку
      </Button>

      {/* Модальное окно с формой */}
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW="80%" maxH="fit-content" background='secondaryGray.300' p="20px">
          <ModalBody>
            <RequestFormSteps
              formData={formData}
              handleChange={handleChange}
              handleFileUpload={handleFileUpload}
              handleSubmit={handleSubmit}
              isFormModified={isFormModified}
              onClose={onClose}
              onSuccessOpen={onSuccessOpen}  // Передаем функции открытия и закрытия модальных окон
              onDraftOpen={onDraftOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Модальное окно с подтверждением отправки */}
      <Modal isOpen={isSuccessOpen} onClose={onSuccessClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Заявка успешно сохранена!</ModalHeader>
          <ModalBody>
            <Text>Ваша заявка успешно сохранена и направлена в работу.</Text>
          </ModalBody>
          <ModalFooter>
            <Button color="green.500" onClick={onSuccessClose}>
              Закрыть
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Модальное окно с вопросом о черновике */}
      <Modal isOpen={isDraftOpen} onClose={onDraftClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Сохранить заявку в черновиках?</ModalHeader>
          <ModalBody>
            <Text>Вы внесли изменения. Хотите сохранить заявку в черновиках перед закрытием?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveDraft}>
              Да
            </Button>
            <Button colorScheme="red" onClick={() => { onDraftClose(); onClose(); resetForm(); }}>
              Нет
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestModal;
