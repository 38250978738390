import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Text,
  FormControl,
  FormLabel,
  useColorModeValue,
  Select,
  Icon,
} from '@chakra-ui/react';
import { MdCheckCircle, MdDescription } from 'react-icons/md';
import { BsBank, BsCashCoin } from "react-icons/bs";
import { TbUserDollar } from "react-icons/tb"
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import IconBox from "components/icons/IconBox";


const OrderParams = ({ order, user, onNext }) => {
  const [formData, setFormData] = useState(order); // Состояние для полей заявки
  const [isChanged, setIsChanged] = useState(false); // Состояние для отслеживания изменений

  const inputBgColor = useColorModeValue('white', 'gray.800'); // Цвет фона инпутов
  const [isEditing, setIsEditing] = useState(false);
  
  // Обработчик для кнопки "Изменить"
  const handleEditClick = () => {
    setIsEditing(true);
  };


  const operationType =  formData.type

  // Обработка изменений полей
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setIsChanged(true); // Отслеживаем изменения
  };

  const renderCheckmark = (index) => {
    return order.checkMarks[index] === 1 ? (
      <Icon as={MdCheckCircle} color="green" boxSize={5} ml={2} />
    ) : null;
  };


  return (
    <Box>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {/* Блок: Банк Получателя или Отправителя */}
        <Box p={4} bg="gray.100" borderRadius="md">
          <Flex alignItems="center" mb={4} >
    <IconBox
          w='30px'
          h='30px'
          bg="white"
          float="left"
          mr="10px"
          icon={
            <Icon w='22px' h='22px' as={BsBank} color="navy.400" />
          }
        />
<Text fontSize="md" fontWeight="bold">
{operationType === 'import' ? 'Банк Получателя / Beneficiary Bank' : 'Банк Отправителя / Sender Bank'}
</Text>
    </Flex>
          <Box>
            <FormControl>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Наименование Банка Получателя' : 'Наименование Банка Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Name' : 'Sender Bank Name'}
</Text>
</Flex>
{renderCheckmark(0)}
</Flex>
</FormLabel>
              <Input
                name="partnerBankName"
                value={formData.partnerBankName || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Страна Банка Получателя' : 'Страна Банка Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Country' : 'Sender Bank Country'}
</Text>
</Flex>
{renderCheckmark(1)}
</Flex>
</FormLabel>
              <Input
                name="partnerBankCountry"
                value={formData.partnerBankCountry || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Адрес Банка Получателя' : 'Адрес Банка Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Address' : 'Sender Bank Address'}
</Text>
</Flex>
{renderCheckmark(2)}
</Flex>
</FormLabel>
              <Input
                name="partnerBankAddress"
                value={formData.partnerBankAddress || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Код Банка Получателя (SWIFT)' : 'Код Банка Отправителя (SWIFT)'}
 </Text>
<Text fontSize="xs" color="gray.500">
  SWIFT ID# of Foreign Bank
</Text>
</Flex>
{renderCheckmark(3)}
</Flex>
</FormLabel>
              <Input
                name="partnerBankSwift"
                value={formData.partnerBankSwift || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>


            
          </Box>
        </Box>

        {/* Блок: Получатель */}
        <Box p={4} bg="gray.100" borderRadius="md">
        <Flex alignItems="center" mb={4} >
    <IconBox
          w='30px'
          h='30px'
          bg="white"
          float="left"
          mr="10px"
          icon={
            <Icon w='22px' h='22px' as={TbUserDollar} color="navy.400" />
          }
        />
<Text fontSize="md" fontWeight="bold">
{operationType === 'import' ? 'Получатель / Beneficiary' : 'Отправитель / Sender'}
</Text>
    </Flex>

          <Box>
            <FormControl>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Наименование Получателя' : 'Наименование Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Name on Account' : 'Sender Name on Account'}
</Text>
</Flex>
{renderCheckmark(4)}
</Flex>
</FormLabel>
              <Input
                name="partnerName"
                value={formData.partnerName || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Адрес Получателя' : 'Адрес Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Address' : 'Sender Address'}
</Text>
</Flex>
{renderCheckmark(5)}
</Flex>
</FormLabel>
              <Input
                name="partnerAddress"
                value={formData.partnerAddress || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Счет Получателя' : 'Счет Отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Beneficiary Bank Account# or IBAN#' : 'Sender Bank Account# or IBAN#'}
</Text>
</Flex>
{renderCheckmark(6)}
</Flex>
</FormLabel>
              <Input
                name="partnerAccount"
                value={formData.partnerAccount || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>


            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
<Text fontSize="sm">
{operationType === 'import' ? 'Веб-сайт получателя' : 'Веб-сайт отправителя'}
 </Text>
<Text fontSize="xs" color="gray.500">
Website
</Text>
</Flex>
{renderCheckmark(7)}
</Flex>
</FormLabel>
              <Input
                name="partnerWebsite"
                value={formData.partnerWebsite || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

          </Box>
        </Box>

        {/* Блок: Контракт и счет */}
        <Box p={4} bg="gray.100" borderRadius="md">
        <Flex alignItems="center" mb={4} >
    <IconBox
          w='30px'
          h='30px'
          bg="white"
          float="left"
          mr="10px"
          icon={
            <Icon w='22px' h='22px' as={HiOutlineClipboardDocumentList} color="navy.400" />
          }
        />
<Text fontSize="md" fontWeight="bold">
Контракт и счёт / Contract and Invoice
</Text>
    </Flex>

          <Box>
            <FormControl>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Описание товара/услуги</Text>
<Text fontSize="xs" color="gray.500">Product/service description</Text>
</Flex>
{renderCheckmark(8)}
</Flex>
</FormLabel>
              <Input
                name="productDescription"
                value={formData.productDescription || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Номер инвойса</Text>
<Text fontSize="xs" color="gray.500">No of invoice</Text>
</Flex>
{renderCheckmark(9)}
</Flex>
</FormLabel>
              <Input
                name="invoiceNumber"
                value={formData.invoiceNumber || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Дата инвойса</Text>
<Text fontSize="xs" color="gray.500">Date of invoice</Text>
</Flex>
{renderCheckmark(10)}
</Flex>
</FormLabel>
              <Input
                name="invoiceDate"
                type="date"
                value={formData.invoiceDate || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">
{operationType === 'import' ? 'Номер контракта c поставщиком' : 'Номер контракта c покупателем'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'No of contract with supplier' : 'No of contract with buyer'}
</Text>
</Flex>
{renderCheckmark(11)}
</Flex>
</FormLabel>
              <Input
                name="contractNumber"
                value={formData.contractNumber || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">
            {operationType === 'import' ? 'Дата контракта c поставщиком' : 'Дата контракта c покупателем'}
 </Text>
<Text fontSize="xs" color="gray.500">
{operationType === 'import' ? 'Date of contract with supplier' : 'Date of contract with buyer'}
</Text>
</Flex>
{renderCheckmark(12)}
</Flex>
</FormLabel>
              <Input
                name="contractDate"
                type="date"
                value={formData.contractDate || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">
            <Text fontSize="sm">Код ТНВД (необязательно)</Text>
<Text fontSize="xs" color="gray.500">TNVD Code</Text>
</Text>
</Flex>
{renderCheckmark(13)}
</Flex>
</FormLabel>
              <Input
                name="tnvdCode"
                value={formData.tnvdCode || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>



          </Box>
        </Box>

        {/* Блок: Платеж */}
        <Box p={4} bg="gray.100" borderRadius="md">
        <Flex alignItems="center" mb={4} >
    <IconBox
          w='30px'
          h='30px'
          bg="white"
          float="left"
          mr="10px"
          icon={
            <Icon w='22px' h='22px' as={BsCashCoin} color="navy.400" />
          }
        />
<Text fontSize="md" fontWeight="bold">
Платеж / Payment
</Text>
    </Flex>
       
          <Box>
            <FormControl>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Назначение платежа</Text>
<Text fontSize="xs" color="gray.500">Details of payment</Text>
</Flex>
{renderCheckmark(14)}
</Flex>
</FormLabel>
              <Input
                name="paymentPurpose"
                value={formData.paymentPurpose || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Сумма платежа</Text>
<Text fontSize="xs" color="gray.500">Amount of payment</Text>
</Flex>
{renderCheckmark(15)}
</Flex>
</FormLabel>
              <Input
                name="paymentAmount"
                type="number"
                value={formData.paymentAmount || ''}
                onChange={handleChange}
                bg={inputBgColor}
              />
            </FormControl>

            <FormControl mt={4}>
            <FormLabel>
            <Flex align="center" justify="space-between">
            <Flex direction="column">
            <Text fontSize="sm">Валюта</Text>
<Text fontSize="xs" color="gray.500">Currency</Text>
</Flex>
{renderCheckmark(14)}
</Flex>
</FormLabel>
              <Select
                name="currency"
                value={formData.currency || ''}
                onChange={handleChange}
                bg={inputBgColor}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="RUB">RUB</option>
                <option value="CNY">CNY</option>
                <option value="AED">AED</option>
                <option value="CNH">CNH</option>
              </Select>
            </FormControl>

   {/* Блок для подтверждения банковских реквизитов */}
   <Box mt={8}>
          <Text fontSize="sm" fontWeight="bold">
            Проверьте реквизиты Вашего Банка
          </Text>
          <Text fontSize="sm" fontWeight="bold" mb={4}>
            и при необходимости измените их:
          </Text>
          
          {/* Поля банковских реквизитов */}
          <FormControl mb={4}>
            <FormLabel fontSize="sm">БИК</FormLabel>
            <Input
            type="number"
              name="BIC"
              value={user.BIC || ''}
              onChange={handleChange}
              bg={inputBgColor}
              disabled={!isEditing}
              borderColor={isEditing ? 'red.500' : 'gray.200'} // Устанавливаем красную обводку при редактировании
              borderWidth={isEditing ? '2px' : '1px'} // Увеличиваем ширину обводки при редактировании
              _disabled={{ opacity: 1 }} // Отключаем стилизацию disabled по умолчанию
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel fontSize="sm">Наименование</FormLabel>
            <Input
              name="bankName"
              value={user.bankName || ''}
              bg={inputBgColor}
              disabled // Поле остается неактивным
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel fontSize="sm">Кор счет</FormLabel>
            <Input
              name="correspondentAccount"
              value={user.correspondentAccount || ''}
              bg={inputBgColor}
              disabled // Поле остается неактивным
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel fontSize="sm">Расчетный счет</FormLabel>
            <Input
              name="accountNumber"
              value={user.accountNumber || ''}
              onChange={handleChange}
              bg={inputBgColor}
              disabled
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel fontSize="sm">Дата открытия счета</FormLabel>
            <Input
              name="accountOpeningDate"
              value={user.accountOpeningDate || ''}
              onChange={handleChange}
              bg={inputBgColor}
              disabled 
            />
          </FormControl>

          {/* Кнопки подтверждения и изменения */}
          <Flex mt={4} justifyContent="flex-end">
            <Button                       colorScheme="gray"
            fontSize="12px"
            padding="0.5rem 1rem"
            height="unset" onClick={handleEditClick}>
              Изменить
            </Button>
          </Flex>
        </Box>

          </Box>
        </Box>
      </Grid>

      {/* Кнопка "Сохранить изменения" */}
      <Button
        position="fixed"
        bottom="20px"
        right="20px"
        colorScheme="blue"
        size="lg"
        fontSize="15px"
        isDisabled={!isChanged} // Отключаем, если изменений нет
        visibility={isChanged ? 'visible' : 'hidden'} // Видимость кнопки
        onClick={() => {
          console.log('Changes saved:', formData);
          setIsChanged(false); // Сбрасываем флаг изменений после сохранения
        }}
      >
        Сохранить изменения
      </Button>

      {/* Блок с кнопками для договора и счета */}
      <Box mt={12}>
        <Flex align="center" mb={4}>
          <Icon as={MdDescription} color="gray.500" w={12} h={12} mr={4} />
          <Box>
            <Text fontSize="lg">Договор</Text>
            <Flex mt={2}>
              <Button mr={2}>Посмотреть</Button>
              <Button>Скачать</Button>
            </Flex>
          </Box>
        </Flex>

        <Flex align="center" mb={4}>
          <Icon as={MdDescription} color="gray.500" w={12} h={12} mr={4} />
          <Box>
            <Text fontSize="lg">Счет</Text>
            <Flex mt={2}>
              <Button mr={2}>Посмотреть</Button>
              <Button>Скачать</Button>
            </Flex>
          </Box>
        </Flex>
        <Button mt={4} onClick={onNext}>Перейти к следующему</Button>
      </Box>
    </Box>
  );
};



export default OrderParams;
