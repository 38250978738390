import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.jpg";
import { RiUser3Line, RiStoreLine } from "react-icons/ri"; 

function Register() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const [formType, setFormType] = useState("user"); 
  const handleSelectFormType = (type) => {
    setFormType(type); 
  };

  // Функция для валидации, чтобы в ИНН можно было вводить только цифры
  const handleInnInput = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      return e.target.value;
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Зарегистрироваться
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Выберите ваш логин и пароль чтобы войти на платформу.
          </Text>
        </Box>

        <Flex mb="24px" justifyContent="space-between" w="100%">
          <Button
            leftIcon={<RiUser3Line />} 
            w="49%"
            variant={formType === "user" ? "solid" : "outline"} 
            onClick={() => handleSelectFormType("user")}
          >
            Я Клиент
          </Button>
          <Button
            leftIcon={<RiStoreLine />} // Иконка магазина
            w="49%"
            variant={formType === "agent" ? "solid" : "outline"}
            onClick={() => handleSelectFormType("agent")}
          >
            Я Агент
          </Button>
        </Flex>

        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            {formType === "user" && (
              <>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Введите ваш ИНН
                </FormLabel>
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Минимум 8 символов"
                  mb="24px"
                  size="lg"
                  variant="auth"
                  type="number"
                  onChange={(e) => handleInnInput(e)}
                />
                          <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Введите Фамилию и Имя
                </FormLabel>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Введите ваш Телефон
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="+799999999999"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Иванов Иван Иванович"
                  mb="24px"
                  size="lg"
                  variant="auth"
                />
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Введите ваш Email
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="mail@nppu.ru"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
              </>
            )}

            {formType === "agent" && (
              <>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Введите ваш Телефон
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="+799999999999"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Введите Фамилию и Имя
                </FormLabel>
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Иванов Иван Иванович"
                  mb="24px"
                  size="lg"
                  variant="auth"
                />
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Введите ваш Email
                </FormLabel>
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="mail@nppu.ru"
                  mb="24px"
                  size="lg"
                  variant="auth"
                />
              </>
            )}

            {/* Код агента для обеих форм */}
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Код агента (необязательно)
            </FormLabel>
            <Input
              fontSize="sm"
              placeholder="12345"
              mb="24px"
              size="lg"
              variant="auth"
              type="number" // Поле с цифровым типом
            />

            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
            >
              Зарегистрироваться
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Register;
