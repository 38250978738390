import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Users from "db/users.json"; // Импортируем данные из локального файла JSON


// Создаем контекст
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();

  // Инициализируем состояние из `localStorage`, если данные есть
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  // Обновляем `localStorage` при изменении `user`
  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  const login = (loginValue, passValue, clientId, agentId) => {
    if ((loginValue === "client" && passValue === "pass") || (loginValue === "agent" && passValue === "pass")) {
      const userData = {
        clientType: loginValue,
        clientId: clientId,
        agentId: agentId
      };
      setUser(userData); // Устанавливаем стейт пользователя
      navigate("/admin"); // Перенаправляем на основную страницу
    } else {
      alert("Invalid login"); // Простая обработка ошибок
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  const getFullUserData = () => {
    if (!user || !user.clientId) return null;
  
    // Находим пользователя по clientId
    const fullUserData = Users.find((u) => u.clientId === user.clientId) || null;
  
    return fullUserData;
  };

  const getFullAgentData = () => {
    if (!user || !user.agentId) return null;
  
    // Находим агента по agentId
    const agentData = Users.find((u) => u.clientId === user.agentId) || null;
  
    // Если agentData найден, возвращаем только нужные поля, иначе "Неизвестный агент"
    return agentData ? { 
      name: agentData.name, 
      fullCompanyName: agentData.fullCompanyName, 
      INN: agentData.INN 
    } : { name: "Неизвестный агент" };
  };
  

  return (
    <UserContext.Provider value={{ user, login, logout, getFullUserData, getFullAgentData }}>
      {children}
    </UserContext.Provider>
  );
};
