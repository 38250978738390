import { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Input, Text, useToast } from "@chakra-ui/react";

export default function ModalChangePassword({ isOpen, onClose }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = useToast();

  const handleSave = () => {
    if (newPassword !== confirmPassword) {
      toast({
        title: "Пароли не совпадают",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    // Handle password change logic here
    onClose(); // Close the modal after saving
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Изменить пароль</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Старый пароль</Text>
          <Input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} mb={3} />
          <Text>Новый пароль</Text>
          <Input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} mb={3} />
          <Text>Повторите пароль</Text>
          <Input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSave}>Сохранить изменения</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
