
// Chakra imports
import { Box, Flex, Icon, Heading, Link } from "@chakra-ui/react";
import MediumTable from "views/admin/orders/components/MediumTable";
import React, { useContext } from "react";
import { DataContext } from "contexts/DataContext";
import IconBox from "components/icons/IconBox";
import {
  AiOutlineClockCircle, AiOutlineDoubleLeft
} from "react-icons/ai";

export default function Current() {
  const { orders } = useContext(DataContext);
  const currentOrders = orders.filter((order) => order.status === "в работе");
  
  return (
    <>
    <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px" >
    <IconBox
          w='56px'
          h='56px'
          bg="white"
          float="left" 
          icon={
            <Icon w='32px' h='32px' as={AiOutlineClockCircle} color="navy.500" />
          }
        />
<Flex pl="20px" direction="column">

<Heading fontSize="27px"  lineHeight="115%" >
     Заявки в работе
        </Heading>

        <Link display="flex" alignItems="center" fontSize="12px" color="gray.500" href="/admin/orders">
  <Icon w="12px" h="12px" as={AiOutlineDoubleLeft} color="gray.500" mr="2px" /> Вернуться ко всем заявкам
</Link>
</Flex>
</Flex>
    <Box>
        <MediumTable
          tableData={currentOrders}
          status="в работе"
        />
    </Box>
    </>
  );
}
