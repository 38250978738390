/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import RequestModal from 'components/newOrder/NewOrder.js';
import { MdBarChart, MdPerson, MdDescription } from "react-icons/md"; // Пример иконок

export function SidebarLinks() {
  // Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  // Проверяем, является ли текущий путь активным
  const activeRoute = (routePath) => {
    return location.pathname === routePath;
  };

  return (
    <>
      {/* Пример вручную созданных ссылок */}
      <NavLink to="/admin/default">
        <HStack
          spacing={activeRoute("/admin/default") ? "22px" : "26px"}
          py="5px"
          ps="10px"
        >
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box color={activeRoute("/admin/default") ? activeIcon : textColor} me="18px">
            <MdBarChart />
            </Box>
            <Text
              me="auto"
              color={activeRoute("/admin/default") ? activeColor : textColor}
              fontWeight={activeRoute("/admin/default") ? "bold" : "normal"}
            >
              Аналитика
            </Text>
          </Flex>
          <Box
            h="36px"
            w="4px"
            bg={activeRoute("/admin/default") ? brandColor : "transparent"}
            borderRadius="5px"
          />
        </HStack>
      </NavLink>

      <NavLink to="/admin/orders">
        <HStack
          spacing={activeRoute("/admin/orders") ? "22px" : "26px"}
          py="5px"
          ps="10px"
        >
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box color={activeRoute("/admin/orders") ? activeIcon : textColor} me="18px">
              <MdDescription />
            </Box>
            <Text
              me="auto"
              color={activeRoute("/admin/orders") ? activeColor : textColor}
              fontWeight={activeRoute("/admin/orders") ? "bold" : "normal"}
            >
              Мои заявки
            </Text>
          </Flex>
          <Box
            h="36px"
            w="4px"
            bg={activeRoute("/admin/orders") ? brandColor : "transparent"}
            borderRadius="5px"
          />
        </HStack>
      </NavLink>

    

      <NavLink to="/admin/profile">
        <HStack
          spacing={activeRoute("/admin/profile") ? "22px" : "26px"}
          py="5px"
          ps="10px"
        >
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box color={activeRoute("/admin/profile") ? activeIcon : textColor} me="18px">
            <MdPerson />
            </Box>
            <Text
              me="auto"
              color={activeRoute("/admin/profile") ? activeColor : textColor}
              fontWeight={activeRoute("/admin/profile") ? "bold" : "normal"}
            >
              Личный кабинет
            </Text>
          </Flex>
          <Box
            h="36px"
            w="4px"
            bg={activeRoute("/admin/profile") ? brandColor : "transparent"}
            borderRadius="5px"
          />
        </HStack>
      </NavLink>


      <RequestModal />


    </>
  );
}

export default SidebarLinks;
