import { Flex, SimpleGrid, Text, useColorModeValue, Button, Icon } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { MdOutlineMarkUnreadChatAlt } from "react-icons/md";
import React from "react";

export default function AgentInfoCard({ user }) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  return (
    <Card display="flex" direction="column" justifyContent="space-between" mb={{ base: "0px", "xl": "20px" }} p="35px" align="left">
        <Text color={textColorPrimary} fontWeight="bold" fontSize="xl">
          Информация об агенте
        </Text>

      <SimpleGrid columns="1" spacingY="10px" spacingX="40px">
        {[
          ["Контактное лицо:", "name"],
          ["Полное наименование:", "fullCompanyName"],
          ["ИНН:", "INN"]
        ].map(([label, field]) => (
          <Flex key={field} direction="column">
            <Text color={textColorSecondary} fontSize="md">{label}</Text>
            <Text color={textColorPrimary} fontSize="md">
              {Array.isArray(user[field]) ? user[field].join(", ") : user[field] || "---"}
            </Text>
          </Flex>
        ))}
      </SimpleGrid>

      <Flex justifyContent="flex-end" mt="24px">
      <Button
  fontSize="13px"
  padding="0.5rem 1rem"
  minHeight="unset"
  colorScheme="blue"
  leftIcon={<Icon as={MdOutlineMarkUnreadChatAlt} fontSize="20px" />}
>
  Связаться с агентом
</Button>

      </Flex>
    </Card>
  );
}
