// FileUpload.js
import React, { useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Подключите плагины FilePond
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import './Upload.css';

// Зарегистрируйте плагины
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);



const FileUpload = ({ setIsFileUploaded }) => {
    const [files, setFiles] = useState([]);

    return (
        <div>
            <FilePond
                files={files}
                onupdatefiles={(fileItems) => {
                  setFiles();
                  setIsFileUploaded(fileItems.length > 0); // Устанавливаем true, если есть хотя бы один файл
                }}
                allowMultiple={false}                // Разрешить множественную загрузку
                maxFiles={5}                        // Максимальное количество файлов
                name="filepond"                     // Имя файла в запросе
                labelIdle='Перетащите файлы сюда<br>или <span class="filepond--label-action">загрузите их</span>'
                labelInvalidField='Поле содержит недопустимые файлы'
                labelFileWaitingForSize='Ожидание определения размера'
                labelFileSizeNotAvailable='Размер недоступен'
                labelFileLoading='Загрузка'
                labelFileLoadError='Ошибка при загрузке'
                labelFileProcessing='Загрузка'
                labelFileProcessingComplete='Загрузка завершена'
                labelFileProcessingAborted='Загрузка отменена'
                labelFileProcessingError='Ошибка при загрузке'
                labelFileProcessingRevertError='Ошибка при возврате'
                labelFileRemoveError='Ошибка при удалении'
                labelTapToCancel='Нажмите, чтобы отменить'
                labelTapToRetry='Нажмите, чтобы повторить'
                labelTapToUndo='Нажмите, чтобы отменить'
                labelButtonRemoveItem='Удалить'
                labelButtonAbortItemLoad='Отменить'
                labelButtonRetryItemLoad='Повторить'
                labelButtonAbortItemProcessing='Отменить'
                labelButtonUndoItemProcessing='Отменить'
                labelButtonRetryItemProcessing='Повторить'
                labelButtonProcessItem='Загрузить'
                labelMaxFileSizeExceeded='Файл слишком большой'
                labelMaxFileSize='Максимальный размер файла: {filesize}'
                labelMaxTotalFileSizeExceeded='Превышен общий размер файлов'
                labelMaxTotalFileSize='Максимальный общий размер файлов: {filesize}'
                labelFileTypeNotAllowed='Недопустимый тип файла'
                fileValidateTypeLabelExpectedTypes='Разрешены: {allTypes}'
                imageValidateSizeLabelFormatError='Недопустимый тип изображения'
                imageValidateSizeLabelImageSizeTooSmall='Изображение слишком маленькое'
                imageValidateSizeLabelImageSizeTooBig='Изображение слишком большое'
                imageValidateSizeLabelExpectedMinSize='Минимум: {minWidth} × {minHeight}'
                imageValidateSizeLabelExpectedMaxSize='Максимум: {maxWidth} × {maxHeight}'
                imageValidateSizeLabelImageResolutionTooLow='Разрешение слишком низкое'
                imageValidateSizeLabelImageResolutionTooHigh='Разрешение слишком высокое'
               
                acceptedFileTypes={[
                  'application/pdf',            // PDF
                  'application/vnd.ms-excel',   // XLS
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
                  'application/msword',         // DOC
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
                  'image/jpeg',                 // JPEG/JPG
                  'image/png'                   // PNG
              ]}
                maxFileSize="5MB"                   // Ограничение по размеру файла
                server={{
                  process: (fieldName, file, metadata, load, error, progress, abort) => {
                    // Имитируем успешную загрузку через 1 секунду
                    setTimeout(() => {
                        load('unique-file-id'); // Сообщает FilePond, что загрузка завершена
                    }, 1000);

                    // Возвращаем функцию для прерывания (если нужно)
                    return {
                        abort: () => {
                            abort();
                        }
                    };
                },
                revert: (uniqueFileId, load, error) => {
                    // Имитируем успешное удаление файла
                    load();
                },
                load: (source, load, error, progress, abort) => {
                    // Имитируем успешное восстановление файла (необязательно)
                    load();
                }
                }}
            />
        </div>
    );
};

export default FileUpload;
