import React, { useState } from 'react';
import { Flex, Box, Button, Text, VStack, Image, Icon, HStack, Modal, ModalOverlay, useDisclosure, useColorModeValue, ModalContent, ModalCloseButton, ModalBody, ModalFooter, Tooltip, IconButton } from '@chakra-ui/react';
import TransferTemplate from 'views/admin/order/documents/TransferTemplate';
import { FaFileDownload, FaFileUpload, FaCheckCircle, FaDownload, FaEye, FaCheck, FaEdit } from 'react-icons/fa';
import { HiOutlineBanknotes } from "react-icons/hi2";
import { BsBank } from "react-icons/bs";
import FileUpload from "views/admin/profile/components/Upload";
import ReactDOMServer from 'react-dom/server';
import myImage from './1.jpg';

const TransferInstruction = ({ order, user, onNext }) => {
  const [activeStep, setActiveStep] = useState(1);

  const devideAmount = order.currency === 'RUB' ? order.paymentAmount / 100 : order.paymentAmount;
  const multiplyAmount = order.currency === 'RUB' ? order.paymentAmount : order.paymentAmount * 100;
  const comission = order.currency === 'RUB' ? order.paymentAmount / 100 * 3.5 : order.paymentAmount * 3.5;
  const total = order.currency === 'RUB' ? order.paymentAmount + order.paymentAmount / 100 * 3.5 : order.paymentAmount * 100 + order.paymentAmount * 3.5;
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isApproveVisible, setIsApproveVisible] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const approvedStatuses = ["согласование поручения", "оплата", "подписание акта", "исполнена"];


  const handleNextStep = () => {
    if (activeStep < 4) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleEditClick = () => {
    setIsEditVisible(true);
    setIsApproveVisible(false);
  };
  
  const handleApproveClick = () => {
    setIsApproveVisible(true);
    setIsEditVisible(false);
  };

  const handleSubmitClick = () => {
    onNext();
  };

  const handleDownloadTransfer = () => {
    const content = ReactDOMServer.renderToStaticMarkup(<TransferTemplate order={order} user={user} />);
    const element = document.createElement("a");
    const fileContent = `
      <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        <head>
          <meta charset='utf-8'>
          <title>Документ</title>
          <style>${cssStyles}</style>
        </head>
        <body>${content}</body>
      </html>`;
    const blob = new Blob(['\ufeff', fileContent], { type: 'application/msword' });
    const url = URL.createObjectURL(blob);
    element.href = url;
    element.download = "transfer.doc";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };



  const cssStyles = `
  @page {
    size: landscape;
    margin: 1in; /* Устанавливает поля страницы */
  }
  .container {
    font-family: Arial, sans-serif;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    color: #333;
  }
  .title {
    font-family: Arial, sans-serif;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .sectionTitle {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .subSectionTitle {
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .paragraph {
    font-family: Arial, sans-serif;
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .listItem {
    font-family: Arial, sans-serif;
    font-size: 15px;
    margin-bottom: 5px;
  }
  .table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  .signaturesItem {
    width: 50%;
    vertical-align: top;
    padding: 0 10px;
  }

`;

  const steps = [
    {
      id: 1,
      title: '1 шаг. Согласуйте стоимость перевода',
      content: (
        <Flex direction="column" alignItems="center">
        <Flex gap="20px">
        <Box mt={4} mb={4}>
          <Box display="flex" alignItems="center" justifyContent="flex-start" mb={2}>
            <Text fontSize="sm" minWidth="150px" fontWeight="bold">Сумма счета:</Text>
            <Box p="5px 10px" borderWidth="1px" minWidth="140px" borderRadius="md" bg="white" display="flex" alignItems="center">
              { multiplyAmount } RUB
            </Box>
       
          </Box>
    
          <Box display="flex" alignItems="center" justifyContent="flex-start" mb={2}>
            <Text minWidth="150px" fontSize="sm" fontWeight="bold">Сумма Комиссии:</Text>
            <Box borderRadius="md" minWidth="140px" bg="white" borderWidth="1px" p="5px 10px">{ comission } RUB</Box>
          </Box>
    
          <Box display="flex" alignItems="center" justifyContent="flex-start" mb={2}>
            <Text minWidth="150px" fontSize="sm" fontWeight="bold">Сумма возмещения:</Text>
            <Box borderRadius="md" minWidth="140px" bg="white" borderWidth="1px" p="5px 10px">{ total } RUB</Box>
          </Box>
        </Box>
        <Box mt={4} mb={4}>
        <Box p="5px 10px" borderWidth="1px" minWidth="140px" borderRadius="md" bg="white" display="flex" alignItems="center">
              { devideAmount } { order.currency }
            </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-start" mt={4} mb={2}>
            <Text fontSize="sm" fontWeight="bold">Курс 1 RUB = 100 { order.currency }</Text>
          </Box>
    
          <Box display="flex" alignItems="center" justifyContent="flex-start" mb={2}>
            <Text fontSize="sm" fontWeight="bold">Комиссия 3,5%</Text>
          </Box>
        </Box>
        </Flex>
    
        {approvedStatuses.includes(order.status) ? (
        <Flex alignItems="center" justifyContent="center" gap={2} mt={4}>
          <FaCheckCircle color="green.500" />
          <Text color="green.500" fontWeight="bold" fontSize="lg">Стоимость согласована</Text>
        </Flex>
      ) : (
        <Flex justifyContent="center" gap={4} mt={4}>
          <Button
            leftIcon={<FaEdit />}
            colorScheme="gray"
            variant="outline"
            width="150px"
            onClick={handleEditClick}
          >
            Изменить
          </Button>

          <Button
            leftIcon={<FaCheck />}
            colorScheme="blue"
            width="150px"
            onClick={handleNextStep}
          >
            Согласовать
          </Button>
        </Flex>
      )}
    {isApproveVisible && (

<Box maxWidth="470px">
<Text color={textColorPrimary} fontSize="sm" fontWeight="bold" textAlign="center" mt={4} mb={4}>
Загрузите подписанную версию договора
</Text>

<FileUpload setIsFileUploaded={setIsFileUploaded} />

<Button
        colorScheme="blue"
        onClick={handleSubmitClick}
        isDisabled={!isFileUploaded}
      >
        Отправить
      </Button>
</Box>

)}



      </Flex>
      ),
    },
    {
      id: 2,
      title: '2 шаг. Согласуйте форму поручения на перевод',
      content: (

        <Flex direction="column" align="center">

<Image src={myImage} alt="Описание изображения" width="200px" />
      <Box m="0 auto 20px">
          <HStack spacing={2}>
            <Tooltip label="Скачать" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaDownload />} aria-label="Скачать" onClick={handleDownloadTransfer} />
            </Tooltip>
            <Tooltip label="Посмотреть" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaEye />} aria-label="Посмотреть" onClick={onOpen} />
            </Tooltip>
          </HStack>
        </Box>


        {approvedStatuses.includes(order.status) ? (
        <Flex alignItems="center" justifyContent="center" gap={2} mt={4}>
          <FaCheckCircle color="green.500" />
          <Text color="green.500" fontWeight="bold" fontSize="lg">Форма согласована</Text>
        </Flex>
      ) : (
        <Flex justifyContent="center" gap={4} mt={4}>
          <Button
            leftIcon={<FaEdit />}
            colorScheme="gray"
            variant="outline"
            width="150px"
            onClick={handleEditClick}
          >
            Изменить
          </Button>

          <Button
            leftIcon={<FaCheck />}
            colorScheme="blue"
            width="150px"
            onClick={handleApproveClick}
          >
            Согласовать
          </Button>
        </Flex>
      )}

    {isApproveVisible && (

<Box maxWidth="470px">
<Text color={textColorPrimary} fontSize="sm" fontWeight="bold" textAlign="center" mt={4} mb={4}>
Загрузите подписанную версию Поручения
</Text>

<FileUpload setIsFileUploaded={setIsFileUploaded} />

<Button
        colorScheme="blue"
        width="100%"
        ml="auto"
        mr="auto"
        onClick={handleNextStep}
        isDisabled={!isFileUploaded}
      >
        Отправить поручение
      </Button>
</Box>

)}

{isEditVisible && (
    
    <Box maxWidth="470px">
  <Text color={textColorPrimary} fontSize="sm" fontWeight="bold" textAlign="center" mt={4} mb={4}>
    Приложите свою версию поручения
  </Text>

    <FileUpload setIsFileUploaded={setIsFileUploaded}/>
    <Button
        colorScheme="blue"
        width="100%"
        ml="auto"
        mr="auto"
        onClick={handleNextStep}
        isDisabled={!isFileUploaded}
      >
        Отправить поручение
      </Button>


  </Box>

  

)} 


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="90%" maxWidth="900px" pt={8}>
          <ModalCloseButton />
          <ModalBody>
            <TransferTemplate order={order} user={user} />
          </ModalBody>
          <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleDownloadTransfer}>Скачать</Button>
            <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>Закрыть</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>

      ),
    },
    {
      id: 3,
      title: '3 шаг. Подтвердите платеж',
      content: (
        <Flex direction="column" alignItems="center">
<Flex justify="center" align="center" py={10}>
      {/* Левый столбец */}

      <Flex direction="column" align="center" mx={5} width="300px">
      <Icon as={BsBank} boxSize="50px" color="blue.500" />
        <Text fontSize="md" mt={2} fontWeight="bold">Оплата счета</Text>

        <Flex mt={4}>
          <VStack align="stretch" spacing={4} mr={4}>
            <Button           fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative"
          textAlign="left"
          colorScheme="blue" leftIcon={<FaFileDownload fontSize="18px" />}>
              Скачать<br />счёт на оплату
            </Button>
            <Button fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative" variant="outline" leftIcon={<FaEye />}>
              Посмотреть
            </Button>
          </VStack>
          <VStack align="stretch" spacing={4}>
            <Button           fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative"
          textAlign="left"
          colorScheme="blue" leftIcon={<FaFileUpload fontSize="18px" />}>
              Загрузить<br />ПП об оплате
            </Button>
            <Button fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative" variant="outline" leftIcon={<FaEye />}>
              Посмотреть
            </Button>
          </VStack>
        </Flex>
      </Flex>

      {/* Правый столбец */}
      <Flex direction="column" align="center" mx={5} width="300px">
        
        <Icon as={HiOutlineBanknotes} boxSize="50px" color="blue.500" />
        <Text fontSize="md" mt={2} fontWeight="bold">Оплата комиссии</Text>

        <Flex mt={4}>
          <VStack align="stretch" spacing={4} mr={4}>
            <Button fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative"
          textAlign="left"
          colorScheme="blue"  leftIcon={<FaFileDownload fontSize="18px" />}>
              Скачать<br />счёт на оплату
            </Button>
            <Button fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative" variant="outline" leftIcon={<FaEye />}>
              Посмотреть
            </Button>
          </VStack>
          <VStack align="stretch" spacing={4}>
            <Button fontSize="14px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative"
          colorScheme="blue" 
          textAlign="left"
          leftIcon={<FaFileUpload fontSize="18px" />}>
              Загрузить<br />ПП об оплате
            </Button>
            <Button fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative" variant="outline" leftIcon={<FaEye />}>
              Посмотреть
            </Button>
          </VStack>
        </Flex>
      </Flex>
      </Flex>
      <Flex justify="center" align="center" py={5}>

   

    {approvedStatuses.includes(order.status) ? (
        <Flex alignItems="center" justifyContent="center" gap={2} mt={4}>
          <FaCheckCircle color="green.500" />
          <Text color="green.500" fontWeight="bold" fontSize="lg">Форма согласована</Text>
        </Flex>
      ) : (
        <Button colorScheme="blue" size="md" onClick={handleNextStep}>
        Перейти к подтверждению
      </Button>
      )}
 </Flex>
    </Flex>   
      ),
    },
    {
      id: 4,
      title: '4 шаг. Подтвердите получение перевода',
      content: (
        <Flex direction="column" alignItems="center">
              <Flex align="center" mt={4}>
      <Icon as={FaCheckCircle} color="green.500" boxSize={6} mr={2} />
      <Text fontSize="md" fontWeight="bold">
        Агент оплатил Платежное поручение
      </Text>
    </Flex>
          <Image src={myImage} alt="Описание изображения" width="200px" />
      <Box m="0 auto 20px">
          <HStack spacing={2}>
            <Tooltip label="Скачать" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaDownload />} aria-label="Скачать" onClick={handleDownloadTransfer} />
            </Tooltip>
            <Tooltip label="Посмотреть" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaEye />} aria-label="Посмотреть" onClick={onOpen} />
            </Tooltip>
          </HStack>
        </Box>
        <Flex justify="center" align="center" py={5}>


      {approvedStatuses.includes(order.status) ? (
        <Flex alignItems="center" justifyContent="center" gap={2} mt={4}>
          <FaCheckCircle color="green.500" />
          <Text color="green.500" fontWeight="bold" fontSize="lg">Вы подтвердили платеж</Text>
        </Flex>
      ) : (
        <Flex justifyContent="center" gap={4} mt={4}>
      <Button colorScheme="blue" size="md" onClick={handleSubmitClick}>
        Подтвердить получение
      </Button>
        </Flex>
      )}
    </Flex>
        </Flex> 
      ),
    },
  ];

  return (
    <VStack spacing={8} align="stretch">
      {steps.map((step) => (
        <Box
          key={step.id}
          p={6}
          borderRadius="md"
          opacity={approvedStatuses.includes(order.status) || step.id <= activeStep ? 1 : 0.5}
          bg="gray.100"
          pointerEvents={approvedStatuses.includes(order.status) || step.id <= activeStep ? 'auto' : 'none'}
        >
          <Text fontSize="md" fontWeight="bold" mb={2}>{step.title}</Text>
          {step.content}
        </Box>
      ))}
    </VStack>
  );
};

export default TransferInstruction;
